import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import constants from "../constants";

const PrivacyPage = () => (
  <Layout>
    <SEO title="Terms Of Service" />
    <div className="mt-20 max-w-2xl mx-auto pb-12 text-sm leading-6">
      <h2 className="font-medium text-lg pt-4">Terms of Use</h2>
      <p>These Terms of Use were updated as of December 1, 2019.</p>

      <p>
        These Terms of Use (the "Terms") apply to all online visitors to
        indienuggets.com (the “Site”). By using this Site, purchasing products
        through the Site, or subscribing to a subscription services through the
        Site, you ("you" or "User") agree to the terms and conditions in this
        Agreement between you and IndieNuggets. If you do not agree to these
        outlined terms and conditions (the "Terms of Use" or "Agreement"), your
        only recourse is to discontinue use of the Site.
      </p>

      <p>
        IndieNuggets reserves the right to make any changes to these Terms of
        Use and/or our Privacy Policy (which is incorporated herein by
        reference) without prior notification to you. You should re-read this
        Agreement containing the Terms of Use and Privacy Policy from time to
        time in order that you stay informed as to any such changes. If we make
        changes to our Terms of Use and Privacy Policy and you continue to use
        our Site, including making purchases through the Site, you are impliedly
        agreeing to the Terms of Use and Privacy Policy expressed herein. We
        strongly suggest that each time you make a purchase through this Site,
        you re-read the Terms of Use.
      </p>

      <p>
        If you have any questions about these terms, contact us at{" "}
        {constants.EMAIL} .
      </p>

      <h2 className="font-medium text-lg pt-4">
        Use of Site/General Purchase Terms
      </h2>
      <p>
        <strong>1. Acceptance of Terms.</strong> By using the Site, you agree to
        the terms and conditions in this Agreement between you and IndieNuggets.
        You must accept the Terms, in full, before using the Site. You can
        accept the Terms in one of three ways: (a) by clicking to “accept” or
        “agree” to the Terms, where this option is presented or made available
        to you on the Site; (b) by actually using the Site; and/or, (c) by
        making purchases of IndieNuggets products, including any subscriptions
        services. BY USING THE SITE, YOU AGREE TO THE TERMS, IN FULL. IF YOU DO
        NOT AGREE TO THE TERMS, DO NOT USE THE SITE. EXCEPT AS OTHERWISE
        PROVIDED, THE TERMS ARE SUBJECT TO CHANGE AT ANY TIME WITHOUT NOTICE AND
        YOUR CONTINUED USE OF THE SITE, AFTER ANY CHANGE(S) HAVE BEEN
        IMPLEMENTED CONSTITUTES ACCEPTANCE BY YOU OF SUCH CHANGE(S).
      </p>

      <p>
        <strong>2. Privacy Policy.</strong> IndieNuggets cares about the privacy
        of our users. IndieNuggets' Privacy Policy applies to the use of the
        Site, and its terms are made a part of the Terms by this reference. To
        view our Privacy Policy, click here. Additionally, by using the Site,
        you acknowledge that the Internet is not a secure environment and
        sometimes there are interruptions in service or events that are beyond
        the control of IndieNuggets and that communications with IndieNuggets
        can never be guaranteed to be secure. You understand that any message or
        information you send to the Site may be read or intercepted by others,
        even if there is a special notice that a particular transmission is
        encrypted. Nonetheless, we employ reasonable measures to protection your
        information from unauthorized use or disclosure. While we make these
        reasonable precautions against loss or theft of data, IndieNuggets shall
        not be responsible for any data lost or stolen while transmitting
        information on the Internet. While it is IndieNuggets’ objective to make
        the Site accessible 24 hours per day, 7 days per week, the Site may be
        unavailable at any time for any reason including, without limitation,
        routine maintenance.
      </p>

      <p>
        <strong>3. Use and Restrictions.</strong> The Site may be accessed and
        used only by individuals who are 13 years of age or older, and who are
        not barred from using the Site under applicable laws. You may access and
        use the Site only in accordance with all applicable laws and regulations
        and with these Terms of Use. You acknowledge and agree that
        IndieNuggets, in its sole discretion and without notice or any further
        obligation to you, may temporarily suspend or permanently discontinue
        and refuse any and all current and future, access to or use of the Site.
        Purchases from the Site and entering into Subscriptions Service
        agreements may only be done by individuals 18 and older who can legally
        enter into binding agreements.
      </p>

      <p>
        <strong>4. Accounts.</strong> If you wish to purchase a Subscription
        Service or a product through the Site, you will be given the option to
        create a user account. Such account will keep track of your purchases,
        subscriptions, and account information.
      </p>

      <p>
        <strong>5. Passwords.</strong> To create an account, you will be
        required to choose a unique username and strong passwords. IndieNuggets
        is not responsible for inappropriate sharing of logins and passwords and
        further, sharing of login information may result in termination of your
        use of the Site.
      </p>

      <p>
        <strong>6. Credit Card Charges.</strong> IndieNuggets uses Stripe to
        provide billing services. By entering your credit card information, you
        agree and acknowledge that you will be bound by Stripe's terms of use
        and privacy policy in addition to the policies of this Site. You will be
        billed a one-time fee, monthly, or annually depending on the products
        and/or Subscription Services purchased until such time that this
        Agreement is terminated.
      </p>

      <p>
        <strong>7. Disputes.</strong> You agree to submit any disputes regarding
        any charge to your account in writing to IndieNuggets within thirty (30)
        days of such charge, otherwise such dispute will be waived and such
        charge will be final and not subject to challenge.
      </p>

      <p>
        <strong>8. Shipping.</strong> When you place an order on our Site, we
        will ship your order to the address designated by you as long as that
        shipping address can accept packages. We may require verification of
        information prior to the acceptance of any order. We reserve the right,
        without prior notice, to limit the order quantity on any product or
        service, and/or to refuse service to any customer.
      </p>

      <p>
        <strong>9. Returns.</strong> All purchases made through the Site are
        final unless otherwise stated.
      </p>

      <p>
        <strong>10. Risk of Loss.</strong> All products purchased on our Site
        are made pursuant to a shipping contract. This means that the risk of
        loss for all items purchased pass to you upon delivery of the items to
        the carrier. You are responsible for filing any claims with carriers for
        damaged and/or lost shipments.
      </p>

      <p>
        <strong>11. Products Descriptions.</strong> In describing and portraying
        our products on the Site, we attempt to be as accurate as possible.
        However, we do not warrant that the product descriptions are accurate,
        complete, reliable, current, or error-free. We reserve the right to
        correct any typographical errors, inaccuracies, or omissions that may
        relate to product descriptions, pricing, and availability from time to
        time without prior notice. We apologize for any inconvenience.
      </p>

      <p>
        <strong>12. Modifications.</strong> Any Products offered on our Site are
        subject to modification or discontinuation at any time and from time to
        time without notice or obligation to you, provided that any
        then-in-effect Subscription Services will be billed at the
        then-in-effect monthly rate until such time as the Subscription Service
        renews.
      </p>

      <h2 className="font-medium text-lg pt-4">Subscription Services</h2>
      <p>
        <strong>13. Subscription Services.</strong> IndieNuggets offers two
        subscription types (collectively, “Subscription Services”): monthly
        subscriptions that are billed monthly (“Monthly Billable Subscriptions”)
        and annual prepaid subscriptions (“Annual Prepaid Subscriptions”).
      </p>

      <p>
        <strong>14. Monthly Billable Subscriptions.</strong> By purchasing a
        Monthly Billable Subscription, you acknowledge that your subscription
        has an initial and recurring payment feature and you accept
        responsibility for all recurring charges prior to cancellation. Your
        subscription will be automatically extended for successive monthly
        periods and your payment method will automatically be charged for each
        successive monthly period at the then-current subscription rate. To
        cancel your Monthly Billable Subscription at any time, you must logon to
        your account and follow the cancellation procedures or call or email us
        and we will do it for you. If you cancel, you may use your subscription
        until the end of your then-current subscription term and you will be
        billed for the full then-current term. IndieNuggets may submit monthly
        charges without further authorization from you until you provide notice
        that you have terminated this authorization or wish to change your
        payment method. Such notice will not affect charges submitted before
        IndieNuggets reasonably could act on any such notice, which is typically
        three business day. Should your billing method fail at any time, your
        monthly subscription will be suspended until your billing information is
        updated.
      </p>

      <p>
        <strong>15. Prepaid Subscriptions.</strong> By purchasing an Annual
        Prepaid Subscriptions, you acknowledge that your subscription has an
        initial pre-payment feature for twelve months of service. At the end of
        the term, your subscription will be automatically extended for another
        year for Annual Prepaid Subscriptions. Your payment method will
        automatically be charged for each successive term at the then-current
        subscription rate for the Annual Prepaid Subscription. You may only
        cancel any additional terms during the first month of then-in-effect
        additional subscription term. You agree and acknowledge that should you
        cancel in the first month, the price for the first month may be deducted
        from any refund to you.
      </p>

      <p>
        <strong>16. Downloads.</strong> For all downloadable products, you will
        be given the option to download the product up to five times. After five
        downloads, you must repurchase the product to enable further downloads.
        By downloading products, you agree and acknowledge that the use of the
        product is personal to you and that you cannot share the products with
        any other individual.
      </p>

      <h2 className="font-medium text-lg pt-4">
        User and Third Party Content/Communications
      </h2>
      <p>
        <strong>17. User Communications and User Content.</strong> By submitting
        material to this Site (“User Communications”), including, but not
        limited to, information, suggestions, ideas, concepts, know-how,
        techniques, questions, comments or other communication, you warrant that
        such content is original to you, that you own all applicable legal
        rights in such content, and that the content does not and will not
        infringe upon the rights of any other person or entity. Further, by
        submitting any User Communication, you agree and acknowledge that you
        have expressly granted IndieNuggets a royalty-free, perpetual,
        irrevocable, non-exclusive right and license to use, reproduce, modify,
        adapt, publish, translate, and distribute such material (in whole or in
        part) worldwide and/or to incorporate it in other works in any form,
        media, or technology now known or hereafter developed for the full term
        of any copyright that may exist in such material. You also permit any
        other users to access, view, store, or reproduce the material if posted
        in a public area of the Site for that user's personal use. You hereby
        grant IndieNuggets the right to edit, copy, publish and distribute any
        material made available on this Site by you, including, but not limited
        to, information, suggestions, ideas, events, comments, commentary, and
        other postings. You agree that we may use any User Communication for any
        purpose in our sole discretion, including reproduction, transmission,
        disclosure, publication, broadcast, development, manufacturing and/or
        marketing in any manner whatsoever for any or all commercial or
        non-commercial purposes. You agree that IndieNuggets shall be under no
        obligation: (a) to maintain any User Communication in confidence; (b) to
        pay compensation for any User Communication; and/or, (c) to monitor,
        use, return, review or respond to any User Communication. You further
        agree that you will not upload, post or otherwise make available on the
        Site any material protected by copyright, trademark, patent, trade
        secret or any other proprietary right without the express permission of
        the owner of such copyright, trademark, patent, trade secret or other
        proprietary right owned by a third party, and the burden of determining
        whether any material is protected by any such right is on you. You shall
        be solely liable for damage resulting from your account, or any
        infringement of copyrights, trademarks, patents, or other proprietary
        rights, or any violations of rights of privacy or publicity, or any
        other harm resulting from any Submission that you make.
      </p>

      <p>
        <strong>18. No Liability for User Communication.</strong> We will have
        no liability related to the content of any User Communication, whether
        or not arising under the laws of copyright, libel, privacy, obscenity,
        or otherwise. You warrant, certify, and represent that any individuals
        depicted in audio or visual files submitted as part of a User
        Communication, other than yourself, are of the age of majority in their
        respective states of residence; and you grant the right to IndieNuggets
        to copy, edit, change, revise, display, perform, publish, distribute the
        likenesses of those individuals and that you have the authority to
        attest to this release on their behalves. In addition, to the extent any
        User Communication contains your or any other person's name, likeness,
        voice or biographical information ("Personal Rights"), you hereby grant
        and will cause such other person to grant to IndieNuggets a
        sublicensable, transferable, perpetual, nonexclusive, worldwide,
        royalty-free license to (in any media, whether now known or not
        currently known or invented) exploit or use such Personal Rights for any
        advertising or marketing of IndieNuggets products and services (in any
        media, whether now known or not currently known or invented). User
        Communications shall be considered non-confidential and IndieNuggets is
        under no obligation to treat such User Communications as proprietary
        information except pursuant to IndieNuggets’ Privacy Policy applicable
        to personally identifiable information. If any of the individuals
        depicted in any User Communication are minors in their respective states
        of residence, you certify, warrant and represent that you are the parent
        or legal custodian of each such individual and you grant the use of the
        media containing his/her depiction in accordance with the Terms.
      </p>

      <p>
        <strong>19. User Content.</strong> When submitting User Communications
        to or otherwise using the Site, you agree not to, without limitation:
        (a) defame, abuse, harass, stalk, threaten, or otherwise violate the
        legal rights (such as rights of privacy and publicity) of others; (b)
        use racially, ethnically, or otherwise offensive language; (c) discuss
        or incite illegal activity; (d) use explicit/obscene language or
        solicit/post sexually explicit images (actual or simulated); (e) post
        anything that exploits children or minors or that depicts cruelty to
        animals; (f) post any copyrighted or trademarked materials without the
        express permission from the owner of such copyright or trademark; (g)
        post any person's name, likeness, voice or biographical information
        without the express permission from such person (or if that person is a
        minor, from that person's parent or legal guardian); (h) disseminate any
        unsolicited or unauthorized advertising, promotional materials, 'junk
        mail', 'spam', 'chain letters', 'pyramid schemes', or any other form of
        such solicitation; (i) use any robot, spider, scraper or other automated
        means to access the Site; (j) take any action that imposes an
        unreasonable or disproportionately large load on our infrastructure;
        and/or (k) alter the opinions, goals, profiles or comments posted by
        others on the Site. This list of prohibitions provides examples and is
        not complete or exclusive. IndieNuggets reserves the right in its sole
        discretion to (a) terminate your access to your account; (b) terminate
        your ability to post to the Site (or the services) and (c) refuse,
        delete, modify, edit or remove any User Communications; with or without
        cause and with or without notice, for any reason or no reason, or for
        any action that IndieNuggets determines is inappropriate or disruptive
        to this Site or to any other user of the Site and/or services.
        IndieNuggets may report to law enforcement authorities any actions that
        may be illegal, and any reports it receives of such conduct. When
        legally required or at IndieNuggets' discretion, IndieNuggets will
        cooperate with law enforcement agencies in any investigation of alleged
        illegal activity on the Site or on the Internet.
      </p>

      <p>
        <strong>20. Third Party User Communications.</strong> IndieNuggets takes
        no responsibility and assumes no liability for any User Communications
        posted or uploaded by you or any third party, or for any mistakes,
        defamation, slander, libel, omissions, falsehoods, obscenity,
        pornography or profanity you may encounter. As a provider of products
        and reviews, IndieNuggets is only a forum and is not liable for any
        statements, representations, or User Communications provided by its
        users in any public forum or personal home page. These prohibitions do
        not require IndieNuggets to monitor, police, or remove any User
        Communications or other information submitted by you or any other user.
      </p>

      <p>
        <strong>21. Links to Third Party Websites.</strong> The Site may contain
        links to websites and other resources operated by third parties other
        than IndieNuggets. Such links are provided solely as a convenience to
        you. IndieNuggets does not control such websites, and is not responsible
        for the content, products, services, or information offered by any third
        parties. The inclusion of links to such websites on the Site does not
        imply any endorsement of any website or the content, products or
        services offered, advertised, endorsed or promoted by any third party,
        or of any company or person. If you decide to access any third party
        websites or acquire any third party products or services, you do so
        entirely at your own risk, and you may be subject to the terms and
        conditions and the privacy policies imposed by such third parties.
      </p>

      <h2 className="font-medium text-lg pt-4">Liability/Warranties</h2>
      <p>
        <strong>22. PRODUCT LIABILITY.</strong> IN NO EVENT WILL INDIENUGGETS BE
        LIABLE TO YOU FOR ANY DAMAGES CAUSED BY THE USE OF PRODUCTS ON THIS
        SITE. YOU UNDERSTAND THAT THE PRODUCTS YOU ARE BUYING THROUGH THIS SITE
        IF USED INCORRECTLY OR AGAINST THE RECOMMENDED USE MAY BE HAZARDOUS TO
        YOUR HEALTH, INCLUDING YOUR FINANCIAL HEALTH, OR SOMEONE ELSE'S. YOU
        AGREE AND ACKNOWLEDGE THAT IT IS YOUR RESPONSIBILITY TO USE THE PRODUCTS
        IN A SAFE MANNER. YOU TAKE FULL RESPONSIBILITY FOR THE USE OF ANY
        PRODUCT YOU BUY THROUGH THIS SITE AND AGREE TO INDEMNIFY AND HOLD
        HARMLESS INDIENUGGETS FOR ANY INJURIES CAUSED BY THE USE OF SUCH
        PRODUCTS.
      </p>

      <p>
        <strong>23. WARRANTIES AND DISCLAIMER.</strong> THE SITE, USER
        COMMUNICATIONS, AND ANY CONTENT PROVIDED VIA THE SITE, INCLUDING LINKS,
        ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS WITH NO WARRANTY OF
        ANY KIND. TO THE MAXIMUM EXTENT PERMITTED BY LAW, INDIENUGGETS DISCLAIMS
        ALL REPRESENTATIONS, WARRANTIES, AND CONDITIONS, EXPRESS OR IMPLIED,
        WITH RESPECT TO THE SITE, USER COMMUNICATIONS, THE CONTENT AND ANY
        PRODUCTS OR SERVICES, INCLUDING, WITHOUT LIMITATION, WARRANTIES OR
        CONDITIONS OF MERCHANTABLE QUALITY, MERCHANTABILITY, DURABILITY, FITNESS
        FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, FREEDOM FROM ERRORS,
        OMISSIONS, COMPUTER VIRUSES, OR OTHER MALICIOUS OR UNAUTHORIZED CODE OR
        PROGRAMS, AND IMPLIED WARRANTIES OR CONDITIONS ARISING FROM COURSE OF
        DEALING OR COURSE OF PERFORMANCE. IN ADDITION, INDIENUGGETS DOES NOT
        REPRESENT OR WARRANT THAT THE SITE, USER COMMUNICATIONS, CONTENT OR ANY
        PRODUCTS, SERVICES OR ANY OTHER INFORMATION ACCESSIBLE VIA THE SITE IS
        SECURE, ACCURATE, COMPLETE OR CURRENT OR THAT ANY PARTICULAR PRODUCTS OR
        INVENTORY WILL BE AVAILABLE AT THE TIME OF ORDER. AS A CONDITION OF YOUR
        USE OF THE SITE, YOU WARRANT TO INDIENUGGETS THAT YOU WILL NOT USE THE
        SITE FOR ANY PURPOSE THAT IS UNLAWFUL OR PROHIBITED BY THESE TERMS OF
        USE. IN ADDITION, THE SITE MAY CONTAIN TYPOGRAPHICAL ERRORS OR
        INACCURACIES AND MAY NOT BE COMPLETE OR CURRENT. INDIENUGGETS THEREFORE
        RESERVES THE RIGHT TO CORRECT ANY ERRORS, INACCURACIES OR OMISSIONS
        (INCLUDING AFTER AN ORDER HAS BEEN SUBMITTED) AND TO CHANGE OR UPDATE
        INFORMATION AT ANY TIME WITHOUT PRIOR NOTICE.
      </p>

      <p>
        <strong>24. LIMITATION OF LIABILITY.</strong> INDIENUGGETS AND ITS
        PARENT, AFFILIATES, SUBSIDIARIES, OFFICERS, DIRECTORS, EMPLOYEES,
        AGENTS, SUPPLIERS, LICENSORS AND THIRD PARTY PARTNERS WILL NOT BE LIABLE
        FOR ANY INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL, EXEMPLARY, MULTIPLIED
        OR CONSEQUENTIAL DAMAGES, LOST PROFITS OR REVENUES OR FAILURE TO REALIZE
        EXPECTED SAVINGS, OR ANY DAMAGES WHATSOEVER, WHETHER OR NOT SUCH PARTY
        HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, WHETHER
        UNDER CONTRACT, TORT (INCLUDING NEGLIGENCE) OR ANY OTHER THEORY,
        INCLUDING, WITHOUT LIMITATION, ARISING OUT OF OR IN CONNECTION WITH ANY
        LOSS OR OTHER DAMAGES IN CONNECTION WITH ANY UNAVAILABILITY OR
        NONPERFORMANCE OF THE SITE, ERRORS, OMISSIONS, VIRUSES AND MALICIOUS
        CODE. THESE LIMITATIONS WILL APPLY NOTWITHSTANDING ANY FAILURE OF
        ESSENTIAL PURPOSE OF ANY LIMITED REMEDY. CERTAIN LAWS DO NOT ALLOW
        LIMITATIONS ON IMPLIED WARRANTIES OR CONDITIONS, OR THE EXCLUSION OR
        LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY, SOME OR ALL OF THE
        ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS, MAY NOT APPLY TO YOU, AND
        YOU MAY HAVE ADDITIONAL RIGHTS TO THOSE CONTAINED HEREIN.
      </p>

      <h2 className="font-medium text-lg pt-4">
        Intellectual Property Information
      </h2>
      <p>
        <strong>25. Ownership of the Site, Content, and Trademarks. </strong>The
        Site is owned and controlled by IndieNuggets and unless otherwise agreed
        in writing, all materials on our Site, including text, graphics,
        information, content, images, illustrations, designs, icons,
        photographs, video clips, sounds, music, artwork, computer code, and
        other materials, and the copyrights, trademarks, trade dress, and/or
        other intellectual property rights in such materials (collectively, the
        “Content”), are owned, controlled, and/or licensed by IndieNuggets. The
        Site and Content are intended solely for your personal, non-commercial
        use. You may not download and/or copy any of the Content without
        permission from IndieNuggets unless such permission is explicitly
        granted on the Site. Any permitted downloads or permitted copies of the
        Content are for your personal use only. No right, title, or interest in
        any downloaded and/or copied Content is transferred to you as a result
        of any such downloading and/or copying. You may not reproduce (except as
        noted above or on the Site), publish, transmit, distribute, display,
        perform, alter, modify, create derivative works from, sell, exploit,
        and/or otherwise use any of the Content or the Site for any public or
        commercial purpose. IndieNuggets also owns a copyright in the selection,
        coordination, arrangement, and enhancement of such content, as well as
        in the content original to it. You may not modify, publish, transmit,
        participate in the transfer or sale, create derivative works, or in any
        way exploit, any of the content, in whole or in part. You may not upload
        or republish Site Content on any Internet, Intranet, or Extranet site or
        incorporate the information in any other database or compilation.
        IndieNuggets does not permit use of any data mining, robots, scraping,
        and/or similar data-gathering or extraction methods. Certain trademarks,
        trade names, service marks, and logos used or displayed on this Site are
        registered and unregistered trademarks, trade names, and service marks
        of IndieNuggets and its affiliates. Other trademarks, trade names, and
        service marks used or displayed on this Site are the registered and
        unregistered trademarks, trade names, and service marks of third
        parties. Nothing contained on the Site grants or should be construed as
        granting, by implication, estoppel, or otherwise, any license or right
        to you to use any such trademarks, trade names, service marks, or logos
        displayed on such Site.
      </p>

      <p>
        <strong>26. Trademark.</strong> All trademarks, service marks, trade
        names, logos and trade dress, whether registered or unregistered
        including, but not limited to, IndieNuggets , IndieNuggets.com,
        IndieNuggets, and our logo (collectively the "Marks") that appear on the
        Site are proprietary to IndieNuggets or such Marks' respective owners.
        You may not display or reproduce the Marks other than with the prior
        written consent of IndieNuggets, and you may not remove or otherwise
        modify any trademark notices from any Content. In addition, all page
        headers, custom graphics, button icons, and scripts are trademarks,
        service marks, trade names and/or trade dress of IndieNuggets or their
        respective owners, and may not be copied, imitated, or used, in whole or
        in part, without the prior written permission of IndieNuggets.
      </p>

      <p>
        <strong>
          27. Copyright, Trademark, and Intellectual Property Complaints.
        </strong>{" "}
        IndieNuggets respects the intellectual property rights of others. We are
        committed to complying with copyright and related laws, including the
        Digital Millennium Copyright Act (DMCA), and we require all users of the
        Site to comply with these laws. Accordingly, you may not upload, post,
        store any material or content on, disseminate any material or content
        over, or otherwise transmit to or on the Site in any manner material
        that constitutes an infringement of third party intellectual property
        rights, including but not limited to, rights granted by copyright law.
        You may not post, modify, distribute, or reproduce in any way any
        copyrighted material, trademarks, or other proprietary information
        belonging to others without obtaining the prior written consent of the
        owner of such proprietary rights. When IndieNuggets receives proper
        Notification of Alleged Copyright Infringement, IndieNuggets promptly
        removes or disables access to the allegedly infringing material upon
        conclusion the material infringes upon another rights and terminates the
        accounts of repeat infringers as described herein in accordance with the
        Digital Millennium Copyright Act. If you believe that any material on
        the Site infringes upon any copyright that you own or control, please
        see contact us at {constants.EMAIL} for more information on how to
        report infringement of your copyright. IndieNuggets may, at its sole
        discretion, terminate any Users who are deemed by IndieNuggets to be
        have committed copyright infringement, whether or not there is any
        repeat infringement.
      </p>

      <h2 className="font-medium text-lg pt-4">Miscellaneous</h2>
      <p>
        <strong>28. Termination of Agreement.</strong> IndieNuggets may
        terminate this Agreement at any time. Without limiting the foregoing,
        IndieNuggets shall have the right to immediately terminate your use of
        this Site in the event of any conduct by you that IndieNuggets, in its
        sole discretion, considers to be unacceptable, or in the event of any
        breach by you of this Agreement.
      </p>

      <p>
        <strong>29. Termination of Use of Site if Under 13.</strong>{" "}
        IndieNuggets may terminate your use of the Site, delete any content or
        information that you have posted on the Site, refuse any orders, and/or
        prohibit you from using or accessing the Site for any reason, or no
        reason, at any time in its sole discretion, with or without notice,
        including if it believes that you have accessed this Site and are under
        13 year of age or violate these Terms of Service.
      </p>

      <p>
        <strong>30. Waiver.</strong> No action taken pursuant to this Agreement,
        including any investigation by or on behalf of any party, shall be
        deemed to constitute a waiver by the party taking such action of
        compliance with any representation, warranty, covenant, or agreement
        contained herein or therein and in any documents delivered in connection
        herewith or therewith. The waiver by any party hereto of a breach of any
        provision of this Agreement shall not operate or be construed as a
        waiver of any subsequent breach.
      </p>

      <p>
        <strong>31. Time for Claims.</strong> YOU AGREE THAT ANY AND ALL
        DISPUTES, CLAIMS, AND CAUSES OF ACTION ARISING OUT OF OR IN CONNECTION
        WITH THESE TERMS OF USE, THIS OR ANY OTHER SITES MUST BE BROUGHT WITHIN
        ONE (1) YEAR AFTER THE DISPUTE, CLAIM OR CAUSE OF ACTION ACCRUES;
        OTHERWISE, SUCH DISPUTE, CLAIM OR CAUSE OF ACTION SHALL BE PERMANENTLY
        BARRED.
      </p>

      <p>
        <strong>32. Headings.</strong> The headings and captions of this
        Agreement are for convenience and reference only and in no way define,
        describe, extend, or limit the scope or intent of this contract, or the
        intent of any provisions hereof.
      </p>

      <p>
        <strong>33. Severability.</strong> If a court of competent jurisdiction
        holds any provision of this Agreement invalid, such invalidity shall not
        affect the enforceability of any other provisions contained in this
        Agreement and the remaining portions of this Agreement shall continue in
        full force and effect.
      </p>

      <p>
        <strong>34. Governing Law; Venue and Jurisdiction.</strong> By using the
        Site and/or purchasing products from this Site, you agree that the laws
        of Japan , without regard to principles of conflict of laws, will govern
        these Terms of Use and any dispute of any sort that might arise between
        you and IndieNuggets. You agree not to commence or prosecute any action
        in connection therewith other than in the state and federal courts of
        Tokyo, Japan, and you hereby consent to, and waive all defenses of lack
        of personal jurisdiction and forum non conveniens with respect to, venue
        and jurisdiction in the state and federal courts of Tokyo, Japan.
      </p>

      <p>
        <strong>35. Indemnity. </strong>You agree to indemnify and hold
        IndieNuggets, its subsidiaries and affiliates, and each of their
        directors, officers, agents, contractors, partners, and employees,
        harmless from and against any loss, liability, claim, demand, damages,
        costs, and expenses, including reasonable attorneys fees, arising out of
        or in connection with any use of the Site, your conduct in connection
        with the Site, or with other users of the Site, or any violation of this
        Agreement or of any law or the rights of any third party.
      </p>

      <p>
        <strong>36. No Assignment.</strong> No modification or amendment of this
        Agreement shall be valid unless in writing.
      </p>

      <p>
        <strong>37. Successors and Assigns.</strong> Rights and obligations
        created by this contract shall be binding upon and inure to the benefit
        of the parties hereto, their successors and assigns.
      </p>

      <p>
        <strong>38. Number and Gender.</strong> Whenever used, the singular
        number shall include the plural, the plural the singular, and the use of
        any gender shall include all genders.
      </p>

      <p>
        <strong>39. Definitions and Constructions.</strong> Unless otherwise
        specified, the terms "includes", "including", "e.g.,", "for example",
        and other similar terms are deemed to include the term "without
        limitation" immediately thereafter. Terms used in this Agreement with
        the initial letter(s) capitalized will have the meaning attributed to
        them in this Agreement.
      </p>

      <p>
        <strong>40. Electronic Communications.</strong> You consent to receive
        electronic communications from IndieNuggets either in the form of email
        sent to you at the email address listed on your account or by
        communications posted on the Site. You acknowledge and agree that any
        electronic communication in the form of such email or posting on the
        Site shall satisfy any legal requirement that such communication be in
        writing.
      </p>
    </div>
  </Layout>
);

export default PrivacyPage;
